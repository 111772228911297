import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchCourses,
  fetchCourse,
  getCompletedExamScores,
  getCourses,
  calcCourses,
  clearCoursesState,
  getAvailableCategories,
} from './coursesThunk';
import { CourseDetailsModel } from '../../models';
import { CoursesState } from './courseRedux.model';
import { initQuestionPositions } from '../../helpers/courseHelpers';

const initialState: CoursesState = {
  coursesDashboard: null,
  currentCourse: null,
  completedCourses: null,
  newCourses: null,
  calcData: null,
  selectedCoursesIds: [],
  categories: {},
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCoursesIds: (state, action: PayloadAction<number[]>) => {
      state.selectedCoursesIds = action.payload;
    },
    resetNewCourses: state => {
      state.newCourses = null;
    },
    setCurrentCourse: (state, action: PayloadAction<CourseDetailsModel | null>) => {
      state.currentCourse = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchCourses.fulfilled, (state, action) => {
      state.coursesDashboard = action.payload.courses;
    });
    builder.addCase(clearCoursesState.fulfilled, state => {
      state.coursesDashboard = null;
      state.currentCourse = null;
      state.completedCourses = null;
      state.newCourses = null;
      state.calcData = null;
      state.selectedCoursesIds = [];
    });
    builder.addCase(fetchCourse.fulfilled, (state, action) => {
      state.currentCourse = action.payload;
    });
    builder.addCase(getCompletedExamScores.fulfilled, (state, action) => {
      state.completedCourses = action.payload.courses;
    });
    builder.addCase(getCourses.fulfilled, (state, action) => {
      state.newCourses = action.payload.data;
    });
    builder.addCase(getAvailableCategories.fulfilled, (state, action) => {
      state.categories = {
        ...state.categories,
        [action.meta.arg.courseId]: initQuestionPositions(action.payload.data),
      };
    });
    builder.addCase(calcCourses.fulfilled, (state, action) => {
      state.calcData = action.payload;
    });
  },
});

export const { setCoursesIds, resetNewCourses, setCurrentCourse } = coursesSlice.actions;

export default coursesSlice.reducer;
