export enum ROUTES {
  SIGN_IN = '/student/sign-in',
  SIGN_UP_STEP_1 = '/student/sign-up/step1',
  SIGN_UP_STEP_2 = '/student/sign-up/step2',
  SIGN_UP_STEP_3 = '/student/sign-up/step3',
  SIGN_UP_THX = '/student/sign-up/thanks',
  FORGOT_PASSWORD = '/student/forgot-password',
  SET_FORGOT_PASSWORD = '/student/set-forgot-password',
  SELECT_FREE_PRACTICE_EXAM = '/student/free-exams',
  FREE_PRACTICE_EXAM = '/student/exam-question/:id',
  FREE_PRACTICE_EXAM_RESULT = '/student/exam-question/result',
  COURSE_LIST = '/student/course-list',
  ACTIVE_COURSES = '/student/courses/active-courses',
  COMPLETED_COURSES = '/student/courses/completed-courses',
  EXPIRED_COURSES = '/student/courses/expired-courses',
  COURSE_DETAILS = '/student/course/:id',
  COURSES_SCORE = '/student/courses-score',
  OTG_RESOURCES = '/student/resources/:tab',
  OTG_COURSE_DETAIL = '/student/otg-course-detail',
  COURSE_QUIZ_RESULT_PROGRESS_ID = '/student/:id/course-quiz-result/:progressId',
  COURSE_SCORE_BY_CATEGORIES = '/student/courses-score/:examProgressId/by-category',
  COURSE_DETAILS_ASSIGNMENT = '/student/course/:id/assignment/:assignmentId/:assignmentType',
  MY_PROFILE = '/student/my-profile',
  ADD_COURSE_LIST = '/student/add-course/list',
  ADD_COURSE_PAYMENT = '/student/add-course/payment',
  ADD_COURSE_THX = '/student/add-course/thanks',
  ADD_COURSE_ERROR = '/student/add-course/error',
  SIMULATION_EXAM = '/student/course/:id/assignment/:assignmentId/simulation-exam',

  OLD_FREE_EXAMS = '/student/select_free_practice_exam',
  OLD_FORGOT_PASSWORD = '/student/forgot_password',
  OLD_SIGN_UP_STEP_1 = '/student/sign_up',
  OLD_SIGN_UP_STEP_2 = '/student/sign_up/step/2',
  OLD_SIGN_UP_STEP_3 = '/student/sign_up/step/3',
}
