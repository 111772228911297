import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../service/api';
import {
  CourseDetailsModel,
  FetchIndustries,
  AssignmentFileModel,
  ExamData,
  CourseRetakeInfoModel,
  AssignmentModel,
  ExamInfoModel,
} from '../../models';
import { CalcCoursesModel, PaymentPayload, PaymentModel } from '../../models';
import {
  AvailableAudioPayload,
  CreateCustomExamPayload,
  FetchAnswerModel,
  FetchAudioModel,
  FetchCategoriesModel,
  FetchCategoriesPayload,
  FetchCourseRenew,
  FetchCoursesModel,
  FetchExamRenewModel,
  FetchExamResultDetailedModel,
  FetchExamResultModel,
  FetchExamScoresListModel,
  FetchFreeExamModel,
  FetchNewCourses,
  FetchPayment,
  GetAssignmentExamPayload,
  GetAssignmentPayload,
  MakeAnswerPayload,
  MakeCustomAnswerPayload,
  PayCoursePayload,
  PayCourseRenewPayload,
  SectionStatesPayload,
} from './courseRedux.model';

export const fetchCourses = createAsyncThunk<FetchCoursesModel>(
  'coursesSlice/fetchCourses',
  async () => await api.post('student/dashboard/'),
);

export const fetchCourse = createAsyncThunk<CourseDetailsModel, string>(
  'coursesSlice/fetchCourse',
  async id => await api.post(`student/route/${id}/`),
);

export const getAssignmentExam = createAsyncThunk<ExamData, GetAssignmentPayload>(
  'coursesSlice/getAssignmentExam',
  async data => {
    const { assignment_id, type } = data;
    return await api.post(`student/get_assignment/${assignment_id}/${type}/`, {
      assignment_id,
      type,
    });
  },
);

export const getAssignmentPage = createAsyncThunk<
  AssignmentFileModel,
  GetAssignmentPayload
>('coursesSlice/ReturnDataAssignmentPage', async data => {
  const { assignment_id, type } = data;
  return await api.post(`student/get_assignment/${assignment_id}/${type}/`, {
    assignment_id,
    type,
  });
});

export const getCompletedExamScores = createAsyncThunk<FetchExamScoresListModel>(
  'coursesSlice/getCompletedExamScores',
  async () => await api.post('student/view_completed_exam_scores/'),
);

export const getAvailableAudio = createAsyncThunk<FetchAudioModel, AvailableAudioPayload>(
  'coursesSlice/getAvailableAudio',
  async data =>
    await api.post('student/check_audio_explanation_by_id/', JSON.stringify(data)),
);

export const makeAnswer = createAsyncThunk<FetchAnswerModel, MakeAnswerPayload>(
  'coursesSlice/makeAnswer',
  async data => {
    return await api.post('student/make_answer/', JSON.stringify(data));
  },
);

export const makeAnswerCustomExam = createAsyncThunk<
  FetchAnswerModel,
  MakeCustomAnswerPayload
>('coursesSlice/makeAnswerCustomExam', async data => {
  const { body, courseId, examId } = data;
  return await api.post(
    `student/course/${courseId}/custom_exam/${examId}/make_answer/`,
    JSON.stringify(body),
  );
});

export const setSectionStates = createAsyncThunk<string, SectionStatesPayload>(
  'coursesSlice/setSectionStates',
  async data => {
    return await api.post('student/set_section_states/', JSON.stringify(data));
  },
);

export const getExamResult = createAsyncThunk<FetchExamResultModel, string>(
  'coursesSlice/getExamResult',
  async progressId => {
    return await api.post(`student/get_exam_result/${progressId}/`, {
      eprogress_id: progressId,
    });
  },
);

export const getCourses = createAsyncThunk<FetchPayment, PaymentPayload>(
  'coursesSlice/addCourses',
  async ({ search_state, search_industry }) => {
    return await api.get('student/add_courses/', {
      params: { search_state, search_industry },
    });
  },
);

export const payCourses = createAsyncThunk<FetchNewCourses, PayCoursePayload>(
  'coursesSlice/payCourses',
  async data => {
    return await api.post('student/add_courses/', data);
  },
);

export const calcCourses = createAsyncThunk<
  CalcCoursesModel,
  { ids: number[]; discountCode?: string; search_state: number; search_industry: number }
>(
  'coursesSlice/calcCourses',
  async ({ ids, discountCode = '', search_state, search_industry }) => {
    return await api.post('student/calculate/', {
      courses: ids,
      discountcode: discountCode,
      search_state: Number(search_state),
      search_industry: Number(search_industry),
    });
  },
);

export const getExamResultDetailed = createAsyncThunk<
  FetchExamResultDetailedModel,
  number
>('coursesSlice/getExamResultDetailed', async examProgressId => {
  return await api.post('student/get_exam_result_detailed/', {
    exam_progress_id: examProgressId,
  });
});

export const getCourseRenew = createAsyncThunk<CourseRetakeInfoModel, string>(
  'coursesSlice/getCourseRenew',
  async courseId => {
    const response: FetchCourseRenew = await api.get(`student/course_renew/${courseId}/`);
    return response.data;
  },
);

export const clearCoursesState = createAsyncThunk<null>(
  'coursesSlice/clearCoursesState',
  async () => {
    return null;
  },
);

export const payCourseRenew = createAsyncThunk<
  FetchExamRenewModel,
  PayCourseRenewPayload
>('coursesSlice/payCourseRenew', async data => {
  const { courseId, courses, payment_method } = data;
  return await api.post(`student/course_renew/${courseId}/`, {
    courses,
    payment_method,
  });
});

export const getIndustries = createAsyncThunk<FetchIndustries>(
  'coursesSlice/getIndustries',
  async () => {
    return await api.get('student/get_free_practice_exam_industries/');
  },
);

export const createCustomExam = createAsyncThunk<ExamInfoModel, CreateCustomExamPayload>(
  'coursesSlice/createCustomExam',
  async data => {
    const { courseId, body } = data;
    return await api.post(`student/course/${courseId}/custom_exam/`, body);
  },
);

export const runAssignmentExam = createAsyncThunk<ExamData, GetAssignmentExamPayload>(
  'coursesSlice/createCustomExam',
  async data => {
    const { courseId, examId } = data;
    return await api.get(`student/course/${courseId}/custom_exam/${examId}/run_exam/`);
  },
);

export const getAvailableCategories = createAsyncThunk<
  FetchCategoriesModel,
  FetchCategoriesPayload
>('coursesSlice/availableCategories', async data => {
  const { courseId } = data;
  return await api.get(`student/course/${courseId}/custom_exam/available_categories/`);
});

export const getFreePracticeExamByIndustry = createAsyncThunk<FetchFreeExamModel, number>(
  'coursesSlice/getFreePracticeExamByIndustry',
  async industryId => {
    return await api.post('student/free_practice_exam_by_industry/', {
      industry_id: industryId,
    });
  },
);

export const getCoursePlaylists = createAsyncThunk<any, number>(
  'coursesSlice/CoursePlaylists',
  async courseId => {
    return await api.get(`student/course/${courseId}/playlists/`);
  },
);

export const getAudio = createAsyncThunk<any, any>(
  'coursesSlice/getAudio',
  async data => {
    const { assignment_id, type } = data;
    return await api.post(`student/get_assignment/${assignment_id}/${type}/`, {
      assignment_id,
      type,
    });
  },
);

export const getAudioFile = createAsyncThunk<any, number>(
  'coursesSlice/CoursePlaylists',
  async audioId => {
    return await api.get(`additional_func/get_file/audio/${audioId}`);
  },
);
